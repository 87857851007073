import React ,{useEffect, useState} from "react";
import { Card, CardContent, Typography, Box, Grid, Stack } from "@mui/material";
import Azure from "src/images/azure.png";
import Logo from "src/images/unlistedkart.jpeg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { versionStatus } from "src/_services/version";
import SnackBar from "src/components/SnackBar/SnackBar";
import CancelIcon from '@mui/icons-material/Cancel';
import moment from "moment";

const Version = () => {
  const [dbHeath,setDbHealth] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [severity, setSeverity] = React.useState("");
  const [serverVersion,setServerVersion] = useState(false);
  useEffect(() => {
   versionStatus.getDbHealthStatus().then((res)=>{
    if(res.status ===200){
      setDbHealth(true)
    }else if(res.status ===400){
      setDbHealth(false)
    }else{
      setSeverity("error");
      setSnackbarTitle("PAN already used");
      setOpenSnackBar(true);
    }
   })
   versionStatus.getServerVersion().then((res)=>{
    if(res.status ===200){
      setServerVersion(res.data?.data)
    }else if(res.status ===400){
      setDbHealth(false)
    }else{
      setSeverity("error");
      setSnackbarTitle("PAN already used");
      setOpenSnackBar(true);
    }
   })

  }, [])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };
  let ADMIN_CLIENT_COMMIT_ID = process.env.COMMIT_ID ? process.env.COMMIT_ID : ""
  let ADMIN_CLIENT_TIME_STAMP= process.env.TIME_STAMP ? moment(process.env.TIME_STAMP).format('LLLL') :""
  let ADMIN_SERVER_COMMIT_ID = serverVersion?.COMMIT_ID ? serverVersion.COMMIT_ID : ""
  let ADMIN_SERVER_TIME_STAMP= serverVersion?.TIME_STAMP ? moment(serverVersion.TIME_STAMP).format('LLLL') :""
  return (
    <Stack sx={{ background: "#fff", height: "calc(100vh - 65px)" ,display:'flex'}}>
      <Box m={5}>
        <Card sx={{ maxWidth: 900, margin: "auto", padding:{xs:1,sm:2,md:5},mt:{xs:1,sm:5,md:10}}}>
          <CardContent>
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={8}>
                <Box mt={4}>
                  <Typography variant="h4" color="primary" gutterBottom>
                    Client Version
                  </Typography>
                  <Box display="flex" alignItems="center" mb={1} gap={1}>
                    <img src={Azure} alt="" width="40" />
                    {/* <GitBranch size={20} style={{ marginRight: 8 }} /> */}
                    <Typography variant="subtitle1" sx={{fontSize:'2rem'}} fontWeight="bold">
                      {`uk-admin-client-${ADMIN_CLIENT_COMMIT_ID}`}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                  {`Deployed at ${ADMIN_CLIENT_TIME_STAMP}`}
                  </Typography>
                </Box>

                <Box mt={4}>
                  <Typography variant="h4" color="primary" gutterBottom>
                    Server Version
                  </Typography>
                  <Box display="flex" alignItems="center" mb={1} gap={1}>
                    <img src={Azure} alt="" width="40" />
                    {/* <GitBranch size={20} style={{ marginRight: 8 }} /> */}
                    <Typography variant="subtitle1"  sx={{fontSize:'2rem'}} fontWeight="bold">
                    {`uk-admin-server-${ADMIN_SERVER_COMMIT_ID}`}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                  {`Deployed at ${ADMIN_SERVER_TIME_STAMP}`}
                  </Typography>
                </Box>
                <Box mt={4}>
                  <Typography variant="h4" color="primary" gutterBottom>
                    Database Health Status
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                  >{
                    dbHeath ?
                    <CheckCircleOutlineIcon fontSize="small" color="success" /> :
                    <CancelIcon  fontSize="small" color="error" />
                  }
                    <Typography variant="body2">{dbHeath ? "Connected successfully":"Error while connecting"}</Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} container justifyContent="center">
                <Box
                  component="img"
                  sx={{ width: 250, height: 250 }}
                  alt="Qapita Logo"
                  src={Logo}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      <SnackBar
        open={openSnackBar}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
    </Stack>
  );
};

export default Version;
