import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  OutlinedInput,
  FormHelperText,
  Container,
  TextField,
  Typography,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
  Box,
  Grid
} from "@mui/material";
import loadingSpinner from "src/images/loading-spinner.gif";
import * as Yup from "yup";
import { Formik } from "formik";

import like from "src/images/ActiveLike.svg";
import bulb from "src/images/ActiveBulb.svg";
import heart from "src/images/ActiveHeart.svg";
const PREFIX = 'NewsCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  wrapper: `${PREFIX}-wrapper`,
  btnroot: `${PREFIX}-btnroot`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",
    marginBottom: "20%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.btnroot}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  }
}));

function NewsCreateForm(props) {


  const [loading, setLoading] = React.useState(false);
  const [url, setUrl] = React.useState();
  const [initValues, setInitValues] = useState({
    shortDescription: "",
    longDescription: "",
    newsSource: "",
    fileType: "",
    file: "",
    selectedDate: "",
    like: "",
    heart: "",
    bulb: "",
    url: "",
    adminCount:""
  })

  useEffect(() => {
    if (props.mode === "edit") {
      setInitValues(props.data)
    }
  }, [])

  const getWordCount = ((value) => {
    return value?.split(/\s+/).length;
  })


  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/svg+xml",

    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];



  // The first commit of Material-UI
  // const [selectedDate, setSelectedDate] = React.useState(new Date());

  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          shortDescription: Yup.string().required("Required").test("len",
            'Must be maximum of 10 words',
            value => getWordCount(value) <= 10),
          longDescription: Yup.string().required("Required").test("len",
            'Must be maximum of 100 words',
            value => getWordCount(value) <= 100),
           file: Yup.mixed().when("fileType", {
            is: "file",
            then: Yup.mixed()
              .test(
                "fileFormat",
                "Unsupported Format",
                (value) =>  value && SUPPORTED_FORMATS.includes(value.type)
              )
              .test(
                "fileSize",
                "Maximum file size is 10MB",
                (value) => value && value.size < 10242880
              ),
            otherwise: Yup.mixed(),
          }),
        })}
        onSubmit={(values) => {
          setLoading(true);
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Headline*"
                id="shortDescription"
                name="shortDescription"
                onBlur={handleBlur}
                onChange={(e) => {
                  if (getWordCount(e.target.value) <= 10) {
                    setFieldValue("shortDescription", e.target.value)
                  }
                }}
                autoComplete="off"
                fullWidth
                value={values.shortDescription}
                error={Boolean(touched.shortDescription && errors.shortDescription)}
                helperText={touched.shortDescription && errors.shortDescription ? errors.shortDescription : `Max word limit : ${values.shortDescription.length === 0 ? "0" : values.shortDescription.split(/\s+/).length}/10`}
                aria-describedby="outlined-weight-helper-text"
              />
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Body*"
                id="longDescription"
                name="longDescription"
                onBlur={handleBlur}
                onChange={(e) => {
                  if (getWordCount(e.target.value) <= 100) {
                    setFieldValue("longDescription", e.target.value)
                  }
                }}
                autoComplete="off"
                fullWidth
                multiline
                minRows={4}
                value={values.longDescription}
                error={Boolean(touched.longDescription && errors.longDescription)}
                helperText={touched.longDescription && errors.longDescription ? errors.longDescription : `Max word limit : ${values.longDescription.length === 0 ? "0" : values.longDescription.split(/\s+/).length}/100`}
                aria-describedby="outlined-weight-helper-text"
              />
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="News Source"
                id="newsSource"
                name="newsSource"
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                fullWidth
                value={values.newsSource}
                error={Boolean(touched.newsSource && errors.newsSource)}
                helperText={touched.newsSource && errors.newsSource}
                aria-describedby="outlined-weight-helper-text"
              />
              <FormLabel    component="legend">Dates for news</FormLabel>
              <TextField
                fullWidth
                id="endDate"
                type="date"
                error={Boolean(touched.selectedDate && errors.selectedDate)}
                helperText={touched.selectedDate && errors.selectedDate}
                onChange={handleChange}
                onBlur={handleBlur}
                name="selectedDate"
                value={values.selectedDate}
                // InputProps={{ inputProps: { max: `${moment(selectedDate).format("YYYY-MM-DD")}` } }}
                variant="outlined"

              />
              <FormLabel component="legend" style={{marginTop:"8px"}}>Count for reactions</FormLabel>
              <Grid
                container
                className={classes.hdrlblbtn}
                style={{ margin: "0.5rem 0rem", textAlign: "center", color: "#1c1c1c" }}
              >

                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.reaction}>
                  <img src={like} alt={like} width={24} style={{margin:"8px"}} />
                  <span style={{ marginLeft: "5px" }}>  <TextField
                    fullWidth
                    id="endDate"
                    type="number"
                    disabled
                    error={Boolean(touched.like && errors.like)}
                    helperText={touched.like && errors.like}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="like"
                    value={values.like}
                    // InputProps={{ inputProps: { max: `${moment(today).format("YYYY-MM-DD")}` } }}
                    variant="outlined"

                  /></span>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.reaction}>
                  <img src={bulb} alt={bulb} width={24} style={{margin:"8px"}} />
                  <span style={{ marginLeft: "5px" }}>  <TextField
                    fullWidth
                    id="endDate"
                    type="number"
                    disabled
                    error={Boolean(touched.bulb && errors.bulb)}
                    helperText={touched.bulb && errors.bulb}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="bulb"
                    value={values.bulb}
                    // InputProps={{ inputProps: { max: `${moment(today).format("YYYY-MM-DD")}` } }}
                    variant="outlined"
                   
                  /></span>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} className={classes.reaction}>
                  <img src={heart} alt={heart} width={24} style={{margin:"8px"}} />
                  <span style={{ marginLeft: "5px" }}>  <TextField
                    fullWidth
                    id="endDate"
                    type="number"
                    disabled
                    error={Boolean(touched.heart && errors.heart)}
                    helperText={touched.heart && errors.heart}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="heart"
                    value={values.heart}
                    // InputProps={{ inputProps: { max: `${moment(today).format("YYYY-MM-DD")}` } }}
                    variant="outlined"
                   
                  /></span>
                </Grid>

              </Grid>
              <TextField
              style={{marginTop:'1rem'}}
                className={classes.inputMargin}
                variant="outlined"
                label="Admin Count"
                id="adminCount"
                name="adminCount"
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                fullWidth
                type="number"
                value={values.adminCount}
                error={Boolean(touched.adminCount && errors.adminCount)}
                helperText={touched.adminCount && errors.adminCount}
                aria-describedby="outlined-weight-helper-text"
              />


              <FormLabel component="legend">Upload Image</FormLabel>
              <RadioGroup
                // row
                aria-label="gender"
                name="fileType"
                id="fileType"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fileType}
              >
                <FormControlLabel
                  value="file"
                  control={<Radio />}
                  label="File"
                />
                <FormControlLabel value="url" control={<Radio />} label="Url" />
              </RadioGroup>
              {values.fileType === "file" && (
                <div>
                  <Typography className={classes.label}>News Image*</Typography>
                  <OutlinedInput
                    accept="image/*"
                    // className={classes.input}
                    id="icon-button-file"
                    name="file"
                    fullWidth={true}
                    error={touched.file && Boolean(errors.file)}
                    helperText={touched.file ? errors.file : ""}
                    onChange={(e) => {
                      setFieldValue("file", e.currentTarget.files[0]);
                      const url = URL.createObjectURL(e.currentTarget.files[0]);
                      setUrl(url);
                    }}
                    type="file"
                  />

                  <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                    {touched.file ? errors.file : ""}
                  </FormHelperText>
                </div>
              )}
              {values.fileType === "url" && (
                <TextField
                  className={classes.inputMargin}
                  variant="outlined"
                  label="File url"
                  id="url"
                  name="url"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                  fullWidth
                  value={values.url}
                  error={Boolean(touched.url && errors.url)}
                  helperText={touched.url && errors.url}
                  aria-describedby="outlined-weight-helper-text"
                />
              )}
              <Box>
                <img src={values.fileType === "file" ? url : values.url} alt="" width={100} style={{ marginBottom: '1rem' }} />
              </Box>

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false && props.mode === "create" ?
                  "Add" : loading === false && props.mode === "edit" ? "Update"
                    : (
                      <>
                        <img
                          src={loadingSpinner}
                          alt="Logo"
                          className={classes.buttonProgress}
                        />{" "}
                        {props.mode === "create" ? "Add" : "Update"}
                      </>
                    )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default NewsCreateForm;
