import React, { useState } from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";

import loadingSpinner from 'src/images/loading-spinner.gif'

import {
  OutlinedInput,
  FormControl,
  FormHelperText,
  Select,
 
  Container,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import ErrorFocus from "../../../components/ErrorFocus/ErrorFocus";

const PREFIX = 'BankCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: '4px'
  }
}));

function capitalizeFirstLetter(str) {
  return str.toLowerCase().split(' ').map(word => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }).join(' ');
}

function capitalizeFirstLetterForBank(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function BankCreateForm(props) {

  const [loading , setLoading] = useState(false)
  const ifscCodePattern = RegExp(/[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/);
  const initialValues = props.data ? props.data : {
    beneficiryName: "",
    bankName: "",
    accountNumber: "",
    accountType: "",
    ifscCode: "",
    podCopy: "",
    id:'',
    primaryAccount: props.isPrimary ? true :false,
  }


  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];


  return (
    <StyledContainer style= {{marginBottom:16}}>
      <Grid container>

      
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object().shape({
          beneficiryName: Yup.string().required("Required"),
          bankName: Yup.string().required("Required"),
          accountNumber: Yup.string().required("Required"),
          accountType: Yup.string().required("Required"),
          ifscCode: Yup.string()
            .matches(ifscCodePattern, "Enter Valid IFSC Code")
            .required("Required"),
            id:Yup.string().nullable(),
            podCopy: Yup.mixed().when('id', {
              is: value => value && value?.length > 0,
              then: Yup.mixed(),
              otherwise:Yup.mixed().required("A file is required")
              .test(
                "fileFormat",
                "Unsupported Format",
                value => value && SUPPORTED_FORMATS.includes(value.type)
              )
            .test(
              "fileSize",
              "Maximum file size is 10MB",
              value => value && value.size < 10242880
            )
            })
        })}
        onSubmit={(values) => {
          setLoading(true)
          props.formSubmit(values)
            
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Beneficiary Name*"

                id="beneficiryName"
                name="beneficiryName"
                onBlur={handleBlur}
                onChange={(e)=>setFieldValue('beneficiryName',capitalizeFirstLetter(e.target.value))}

                // placeholder="Beneficiry Name"
                fullWidth
                value={values.beneficiryName}
                error={Boolean(touched.beneficiryName && errors.beneficiryName)}
                helperText={touched.beneficiryName && errors.beneficiryName}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus/>

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Bank Name*"
                id="bankName"
                name="bankName"
                onBlur={handleBlur}
                placeholder="Bank Name"
                fullWidth
                onChange={(e)=>setFieldValue('bankName',capitalizeFirstLetterForBank(e.target.value))}
                value={values.bankName}
                error={Boolean(touched.bankName && errors.bankName)}
                helperText={touched.bankName && errors.bankName}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus/>

              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Account Number*"
                id="accountNumber"
                name="accountNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Account Number*"
                fullWidth
                value={values.accountNumber}
                error={Boolean(touched.accountNumber && errors.accountNumber)}
                helperText={touched.accountNumber && errors.accountNumber}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                inputProps={{ maxLength: 20 }}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
              />
              <ErrorFocus/>


              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Account Type*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="accountType"
                  // className={classes.inputMargin}
                  style={{marginBottom:8}}
                  label="*Account Type"
                  // select
                  // SelectProps={{ native: true }}
                  variant="outlined"
                  error={Boolean(touched.accountType && errors.accountType)}
                  // helperText={touched.accountType && errors.accountType}
                  name="accountType"
                  value={values.accountType}
                  placeholder="Account Type"
                  aria-describedby="outlined-weight-helper-text"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem key="saving" value="SAVINGS">
                    Saving
                  </MenuItem>
                  <MenuItem key="current" value="CURRENT">
                    Current
                  </MenuItem>
                </Select>
                <FormHelperText style={{ color: "#F44336" ,position:"absolute",top:"53px"}}>
                  {errors.accountType &&
                    touched.accountType &&
                    errors.accountType}
                </FormHelperText>
              </FormControl>
              <ErrorFocus/>

              <TextField
              style={{marginTop:24}}
                className={classes.inputMargin}
                variant="outlined"
                label="IFSC CODE*"
                id="ifscCode"
                name="ifscCode"
                
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="IFSC Code"
                fullWidth
                value={values.ifscCode?.toUpperCase()}
                error={Boolean(touched.ifscCode && errors.ifscCode)}
                helperText={touched.ifscCode && errors.ifscCode}
                autoComplete="off"
                aria-describedby="outlined-weight-helper-text"
                
              />
              <ErrorFocus/>

             
<div>
                <Typography className={classes.label}>POD Copy*(Passbook,Cancelled Cheque,Bank Details)</Typography>
                <OutlinedInput
                  accept="image/*"
                  // className={classes.input}
                  id="icon-button-file"
                  name="podCopy"
                  fullWidth={true}  
                  error={touched.podCopy && Boolean(errors.podCopy)}
                  // helperText={touched.podCopy ? errors.podCopy : ""}
                  onChange={(e) => {
                    setFieldValue("podCopy", e.currentTarget.files[0]);
                  }}
                  type="file"
                />

                <FormHelperText style={{ color: "#F44336",paddingLeft:16 }}>
                  {touched.podCopy ? errors.podCopy : ""}
                </FormHelperText>
              </div>
              <ErrorFocus/>

              <FormControlLabel
                control={
                  <Checkbox
                  disabled={props.isPrimary}
                    // error={Boolean(
                    //   errors.primaryAccount && touched.primaryAccount
                    // )}
                    // helperText={errors.primaryAccount && touched.primaryAccount}
                    name="primaryAccount"
                    value={values.primaryAccount}
                    checked={values.primaryAccount}
                    aria-describedby="outlined-weight-helper-text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Primary A/C"
              />
              <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                {errors.primaryAccount &&
                  touched.primaryAccount &&
                  errors.primaryAccount}
              </FormHelperText>

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                 {loading === false ? values.id ?"Update" : "Add" : <><img src={loadingSpinner} alt="Logo" className={classes.buttonProgress}/> {values.id ?"Update" : "Add"} </>} 
              </Button>
            </Container>
          </form>
        )}
      </Formik>
      </Grid>
    </StyledContainer>
  );
}

export default BankCreateForm;
