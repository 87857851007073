import React, { useEffect , useState} from "react";
import { styled } from '@mui/material/styles';
import Button from "@mui/material/Button";
import {
  Checkbox,
  Container,
  TextField,
  FormControlLabel,
} from "@mui/material";
import loadingSpinner from "src/images/loading-spinner.gif";

import * as Yup from "yup";
import { Formik } from "formik";

const PREFIX = 'DematCreateForm';

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  buttonProgress: `${PREFIX}-buttonProgress`,
  wrapper: `${PREFIX}-wrapper`,
  btnroot: `${PREFIX}-btnroot`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    // justifyContent:"center",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },

  [`& .${classes.wrapper}`]: {
    margin: theme.spacing(1),
    position: "relative",
  },

  [`& .${classes.btnroot}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  }
}));

function DematCreateForm(props) {


  const [loading, setLoading] = React.useState(false);
  const [initValues, setInitValues]=useState({
    tagName: "",
    fileType: "",
    file: "",
    url: "",
    status: true,
  })
  const timer = React.useRef();
  useEffect(()=>{
    if(props.mode==="edit"){
      setInitValues(props.data)
    }
  },[])

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
    "image/svg+xml",
    
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  return (
    <StyledContainer>
      <Formik
       enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          tagName: Yup.string().required("Required"),
          url: Yup.string().when("fileType", {
            is: "url",
            then: Yup.string().required("Url path is required"),
            otherwise: Yup.string(),
          }),
          file: Yup.mixed().when("fileType", {
            is: "file",
            then: Yup.mixed()
              .test(
                "fileFormat",
                "Unsupported Format ",
                (value) => value && SUPPORTED_FORMATS.includes(value.type)
              )
              .test(
                "fileSize",
                "Maximum file size is 10MB",
                (value) => value && value.size < 10242880
              ),
            otherwise: Yup.mixed(),
          }),
        })}
        onSubmit={(values) => {
          setLoading(true);
          props.formSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <TextField
                className={classes.inputMargin}
                variant="outlined"
                label="Tag Name"
                id="tagName"
                name="tagName"
                onBlur={handleBlur}
                onChange={handleChange}
                autoComplete="off"
                fullWidth
                value={values.tagName}
                error={Boolean(touched.tagName && errors.tagName)}
                helperText={touched.tagName && errors.tagName}
                aria-describedby="outlined-weight-helper-text"
              />

              {/* <FormLabel component="legend">File Type</FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="fileType"
                id="fileType"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fileType}
              >
                <FormControlLabel
                  value="file"
                  control={<Radio />}
                  label="File"
                />
                <FormControlLabel value="url" control={<Radio />} label="Url" />
              </RadioGroup>
              {values.fileType === "file" && (
                <div>
                  <Typography className={classes.label}>Tag Icon*</Typography>
                  <OutlinedInput
                    accept="image/*"
                    // className={classes.input}
                    id="icon-button-file"
                    name="file"
                    fullWidth={true}
                    error={touched.file && Boolean(errors.file)}
                    helperText={touched.file ? errors.file : ""}
                    onChange={(e) => {
                      setFieldValue("file", e.currentTarget.files[0]);
                      const url = URL.createObjectURL(e.currentTarget.files[0]);
                      setUrl(url);
                    }}
                    type="file"
                  />

                  <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                    {touched.file ? errors.file : ""}
                  </FormHelperText>
                </div>
              )}
              {values.fileType === "url" && (
                <TextField
                  className={classes.inputMargin}
                  variant="outlined"
                  label="File url"
                  id="url"
                  name="url"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  autoComplete="off"
                  fullWidth
                  value={values.url}
                  error={Boolean(touched.url && errors.url)}
                  helperText={touched.url && errors.url}
                  aria-describedby="outlined-weight-helper-text"
                />
              )} */}
             {/* <Box>
               <img src={values.fileType ==="file" ? url : values.url} alt=""  width={100} style={{marginBottom:'1rem'}}/>
             </Box> */}

              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.status}
                    name="status"
                    id="status"
                    aria-describedby="outlined-weight-helper-text"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Active"
              />

              <Button
                color="primary"
                disabled={isSubmitting}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {loading === false && props.mode === "create" ? 
                  "Add" :  loading === false && props.mode === "edit" ? "Update" 
                : (
                  <>
                    <img
                      src={loadingSpinner}
                      alt="Logo"
                      className={classes.buttonProgress}
                    />{" "}
                    {props.mode === "create" ? "Add" : "Update"}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </StyledContainer>
  );
}

export default DematCreateForm;
