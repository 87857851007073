import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ErrorFocus from "../../components/ErrorFocus/ErrorFocus";
import {
  Container,
  TextField,
  Grid,
  Typography,
  Chip,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import moment from "moment";
import loadingSpinner from "src/images/loading-spinner.gif";
import SessionExpiredModal from "src/components/AlertModal/SessionExpiredModal";
import { getLookUpDataService } from "src/_services/lookUp";
import Autocomplete from "@mui/material/Autocomplete";
import SnackBar from "src/components/SnackBar/SnackBar";
import { userService } from "src/_services/users";
import ModalCentered from "src/components/Modals/ModalCentered";
import { Radio } from "@mui/material";

const PREFIX = "OrderExportForm";

const classes = {
  zeroPadding: `${PREFIX}-zeroPadding`,
  cardBorderRadius: `${PREFIX}-cardBorderRadius`,
  cardHeaderMargin: `${PREFIX}-cardHeaderMargin`,
  fileInput: `${PREFIX}-fileInput`,
  imageSizes: `${PREFIX}-imageSizes`,
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  marginInputLabel: `${PREFIX}-marginInputLabel`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  resendBtnMargin: `${PREFIX}-resendBtnMargin`,
  inputMargin: `${PREFIX}-inputMargin`,
  paddingInput: `${PREFIX}-paddingInput`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.zeroPadding}`]: {
    padding: 0,
  },

  [`& .${classes.cardBorderRadius}`]: {
    borderRadius: "0 10px 10px 10px",
    width: "100%",
  },

  [`& .${classes.cardHeaderMargin}`]: {
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    padding: 12,
    color: "#fff",
    border: "1px solid #324c90",
    width: "max-content",
    borderRadius: "0 0  10px 0",
  },

  [`& .${classes.fileInput}`]: {
    display: "none",
  },

  [`& .${classes.imageSizes}`]: {
    width: "40%",
    margin: "auto",
    display: "block",
  },

  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",
    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.marginInputLabel}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.popDownBtn}`]: {
    // borderRadius: "0 0 12px 12px",
    padding: "8px",
    textTransform: "none",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.resendBtnMargin}`]: {
    marginTop: theme.spacing(3),
  },

  [`& .${classes.inputMargin}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.paddingInput}`]: {
    padding: "0px 0px",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

const DealtermForMultipleOrders = (props) => {
  const [openSessionExpiredModal, setOpenSessionExpiredModal] = React.useState(
    false
  );
  const [client, setClient] = React.useState([]);
  const [tags, setTags] = React.useState([]);
  const [show, setShow] = React.useState(false);

  const [severity, setSeverity] = React.useState("");
  // const [tags,setTags] = React.useState([]);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [open, setOpen] = React.useState(false);
  const [vendorDematAccountList, setVendorDematAccountList] = React.useState(
    []
  );
  const [vendorBankAccountList, setVendorBankAccountList] = React.useState([]);

  const startDate = new Date();

  const initValues = {
    fromDate: moment(
      new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), 1)
    ).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
    client: "",
    bank: "",
    demat: "",
    tagName: "",
    type: {
      value: 2,
      lable: "Sell",
    },
    filterType: "1",
    // ownedBy: { id: props.authReducer.auth.user.user_unique_code, label: props.authReducer.auth.user.display_name },
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    getLookUpDataService
      .getLookUpUserForDealterm("CHANNEL_PARTNER,INVESTER_CLIENT")
      .then((res) => {
        if (res) {
          if (res.status === 200) {
            let item = res.data.map((items) => {
              return {
                id: items.id,
                code: items.user_code,
                lable: `${
                  items.user_name
                } ( ${items.user_code.toUpperCase()} )`,
              };
            });
            setClient(item);
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const type = [
    {
      value: 2,
      lable: "Sell",
    },
    {
      value: 1,
      lable: "Buy ",
    },
  ];

  const selectOption = (
    value,
    multiple,
    options,
    setFieldValue,
    name,
    label,
    touched,
    errors
  ) => {
    return (
      <Grid item xs={12}>
        <Autocomplete
          multiple={multiple}
          id="client"
          disableClearable
          // name="investorOrVendor"
          value={value}
          options={options}
          onChange={async (e, value) => {
            if (value !== null) {
              if (name === "client") {
                setFieldValue("demat", "");
                setFieldValue("bank", "");
                getVendorBankAccounts(value.id);
                getVendorDematAccounts(value.id);
              }
              setFieldValue(name, value);
            } else {
              setFieldValue(name, "");
            }
          }}
          getOptionLabel={(option) => option.lable || ""}
          renderInput={(params) => (
            <TextField
              error={Boolean(touched && errors)}
              helperText={touched && errors}
              {...params}
              label={label}
            />
          )}
        />
        <ErrorFocus />
      </Grid>
    );
  };

  const handleAddTag = (e, setFieldValue) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      if (e.target.value.includes(" ")) {
        let orderArray = e.target.value
          .split(" ")
          .map((item) => item.trim().replace(",", ""));
        setTags([...tags, ...orderArray]);
      } else {
        setTags([...tags, e.target.value]);
      }
      e.target.value = "";
    }
    setFieldValue("tagName", "");
  };

  const handleDelete = (i) => {
    setTags((tag) => tag.filter((name, index) => index !== i));
  };

  const generateDealTerm = (values) => {
    let orderId = [];
    if (values.tagName?.includes(" ")) {
      let orderArray = values.tagName
        .split(" ")
        .map((item) => item?.trim().replace(",", ""));
      orderId = [...tags, ...orderArray];
    } else {
      orderId = [...tags, values.tagName];
    }
    const orders = orderId
      .map((order) => order.replace(/\D/g, ""))
      .map((order) => Number(order));
    const filteredArr = orders.filter((item) => item !== "" && item !== 0);
    const data = {
      order_id: filteredArr,
      client_id: values.client.id,
      from_date: values.fromDate,
      to_date: values.toDate,
      type: values.type,
      bank: values.bank,
      demat: values.demat,
      client: values.client,
      filter_type: Number(values.filterType),
    };
    props.formSubmit(data);
  };

  const downloadFile = (fileURL) => {
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `Dealterm.pdf`;
    link.click();
    setOpen(true);
    setSeverity("success");
    setSnackbarTitle("Download success");
  };

  const getVendorBankAccounts = async (userId, mode) => {
    return userService
      .getUserBankAccounts(userId)
      .then(async (res) => {
        if (res) {
          if (res.status === 200) {
            let item = await res.data.map((items) => {
              if (items.is_primary === true) {
                return {
                  id: items.id,
                  lable: `${items.bank_name} ( ${items.account_no} ) Primary`,
                  name: items.beneficiary_name,
                  bankName: items.bank_name,
                  accountNumber: items.account_no,
                  ifsc: items.ifsc_code,
                };
              } else {
                return {
                  id: items.id,
                  lable: `${items.bank_name} ( ${items.account_no} )`,
                  name: items.beneficiary_name,
                  bankName: items.bank_name,
                  accountNumber: items.account_no,
                  ifsc: items.ifsc_code,
                };
              }
            });

            let primaryBank = await res.data
              // eslint-disable-next-line array-callback-return
              .map((items) => {
                if (items.is_primary === true) {
                  return {
                    id: items.id,
                    lable: `${items.bank_name} ( ${items.account_no} ) Primary`,
                    name: items.beneficiary_name,
                    bankName: items.bank_name,
                    accountNumber: items.account_no,
                    ifsc: items.ifsc_code,
                  };
                }
              })
              .filter((data) => {
                return data !== undefined;
              });

            setVendorBankAccountList(item);

            return mode ? item : primaryBank;
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getVendorDematAccounts = async (userId, mode) => {
    return userService
      .getUserDemat(userId)
      .then(async (res) => {
        if (res) {
          if (res.status === 200) {
            let item = await res.data.map((items) => {
              if (items.is_primary === true) {
                return {
                  id: items.id,
                  lable: `${items.dp_with} ( ${items.client_id} ) Primary`,
                  client_id: items.client_id,
                  dp_id: items.dp_id,
                  dp_with: items.dp_with,
                };
              } else {
                return {
                  id: items.id,
                  lable: `${items.dp_with} ( ${items.client_id} )`,
                  client_id: items.client_id,
                  dp_id: items.dp_id,
                  dp_with: items.dp_with,
                };
              }
            });

            let primaryDemat = await res.data
              // eslint-disable-next-line array-callback-return
              .map((items) => {
                if (items.is_primary === true) {
                  return {
                    id: items.id,
                    lable: `${items.dp_with} ( ${items.client_id} ) Primary`,
                    client_id: items.client_id,
                    dp_id: items.dp_id,
                    dp_with: items.dp_with,
                  };
                }
              })
              .filter((data) => {
                return data !== undefined;
              });

            setVendorDematAccountList(item);
            return mode ? item : primaryDemat;
          } else {
            if (res.status === 401 && res.data.name === "TokenExpiredError") {
              setOpenSessionExpiredModal(true);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        validationSchema={Yup.object().shape({
          fromDate: Yup.string().required("Required"),
          toDate: Yup.string().required("Required"),
          client: Yup.string().required("Required"),
          type: Yup.string().required("Required"),
          bank: Yup.string().required("Required"),
          demat: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          generateDealTerm(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                {selectOption(
                  values.type,
                  false,
                  type,
                  setFieldValue,
                  "type",
                  "Order Type",
                  touched.type,
                  errors.type
                )}
              </Grid>
              <Grid item xs={6}>
                {selectOption(
                  values.client,
                  false,
                  client,
                  setFieldValue,
                  "client",
                  "Client",
                  touched.client,
                  errors.client
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="fromDate"
                  label="From Date"
                  type="date"
                  name="fromDate"
                  error={Boolean(touched.fromDate && errors.fromDate)}
                  helperText={touched.fromDate && errors.fromDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    inputProps: {
                      max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                    },
                  }}
                  value={values.fromDate}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <ErrorFocus />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="toDate"
                  label="End Date"
                  type="date"
                  name="toDate"
                  value={values.toDate}
                  InputProps={{
                    inputProps: {
                      min: values.fromDate,
                      max: `${moment(new Date()).format("YYYY-MM-DD")}`,
                    },
                  }}
                  error={Boolean(touched.toDate && errors.toDate)}
                  helperText={touched.toDate && errors.toDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // InputProps={{ inputProps: { min: `${moment(values.fromDate).format("YYYY-MM-DD")}` } }}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <ErrorFocus />
              </Grid>
              <Grid item xs={12} sm={12} textAlign={"center"}>
                <Typography>Or</Typography>
              </Grid>
              <Grid item xs={12} sm={6} className={classes.gridPadding}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Filter Type</FormLabel>
                  <RadioGroup
                    row
                    aria-label="payment_mode"
                    name="filterType"
                    value={values.filterType}
                    id="filterType"
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value={"1"}
                      control={<Radio color="primary" />}
                      label="Include"
                    />
                    <FormControlLabel
                      value={"2"}
                      control={<Radio color="primary" />}
                      label="Exclude"
                    />
                  </RadioGroup>
                </FormControl>
                <ErrorFocus />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  helperText=""
                  label={`Search by order id`}
                  onKeyPress={(e) => handleAddTag(e, setFieldValue)}
                  name="tagName"
                  // disabled={orders}
                  onChange={handleChange}
                />
                <div className={classes.wrap}>
                  {tags &&
                    tags.map((item, index) => (
                      <Chip
                        // disabled={orders}
                        style={{ margin: "4px" }}
                        key={index}
                        tabIndex={-1}
                        label={item}
                        onDelete={() => handleDelete(index)}
                      />
                    ))}
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Autocomplete
                  id="demat_id"
                  // name="investorOrVendor"
                  value={values.demat}
                  options={vendorDematAccountList}
                  // isOptionEqualToValue={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.lable || ""}
                  onChange={(e, value) => {
                    if (value !== null) {
                      setFieldValue("demat", value);
                    } else {
                      setFieldValue("demat", "");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.demat && errors.demat)}
                      helperText={touched.demat && errors.demat}
                      value={values.demat}
                      name="demat"
                      label={
                        values.type.value === 2
                          ? "Investor Demat Account *"
                          : "Vendor Demat Account *"
                      }
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
                <ErrorFocus />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <Autocomplete
                  id="bank"
                  // name="investorOrVendor"
                  value={values.bank}
                  // isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={vendorBankAccountList}
                  getOptionLabel={(option) => option.lable || ""}
                  onChange={(e, value) => {
                    if (value !== null) {
                      setFieldValue("bank", value);
                    } else {
                      setFieldValue("bank", "");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(touched.bank && errors.bank)}
                      helperText={touched.bank && errors.bank}
                      value={values.bank}
                      name="bank"
                      label={
                        values.type.value === 1
                          ? "Investor Bank Account *"
                          : "Vendor Bank Account *"
                      }
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
                <ErrorFocus />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                gap={2}
                display={"flex"}
                justifyContent="end"
              >
                <Button
                  color="primary"
                  className={classes.popDownBtn}
                  size="medium"
                  type="submit"
                  disabled={props.spinner}
                  // onClick={()  => generateDealTerm(values)}
                  variant="contained"
                >
                  {props.spinner === false ? (
                    <> {"Generate Dealterm"}</>
                  ) : (
                    <>
                      <img
                        alt="loader"
                        src={loadingSpinner}
                        className={classes.buttonProgress}
                      />{" "}
                      Generate Dealterm{" "}
                    </>
                  )}
                </Button>
                {props.data ? (
                  <Button
                    variant="contained"
                    onClick={() => setShow(true)}
                    className={classes.popDownBtn}
                  >
                    Preview
                  </Button>
                ) : null}
                {props.data ? (
                  <Button
                    variant="contained"
                    onClick={() => downloadFile(props.data)}
                    className={classes.popDownBtn}
                  >
                    Download
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
      <ModalCentered
        title={"Preview"}
        fullScreen={true}
        open={show}
        toggle={() => setShow(false)}
        children={
          <div style={{ textAlign: "center", position: "relative" }}>
            <Grid container style={{ display: "flex" }}>
              <Grid item xs={12} md={12}>
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <iframe
                    title="iframe"
                    style={{ width: "100%", height: "calc(100vh - 100px)" }}
                    src={props.data}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        }
      />
      <SessionExpiredModal open={openSessionExpiredModal} />
      <SnackBar
        open={open}
        severity={severity}
        close={handleClose}
        snackbarTitle={snackbarTitle}
      />
    </StyledContainer>
  );
};

export default DealtermForMultipleOrders;
