import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import loadingSpinner from "src/images/loading-spinner.gif";
import {
  OutlinedInput,
  FormHelperText,
  Container,
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableRow,
  TableBody,
  Table,
  TableHead,
  TableCell,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const PREFIX = "BulkUpload";

const classes = {
  container: `${PREFIX}-container`,
  label: `${PREFIX}-label`,
  popDownBtn: `${PREFIX}-popDownBtn`,
  buttonProgress: `${PREFIX}-buttonProgress`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.container}`]: {
    justifyContent: "center",
    justifyItems: "center",

    marginTop: "10%",
  },

  [`& .${classes.label}`]: {
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
  },

  [`& .${classes.popDownBtn}`]: {
    borderRadius: "0 0 12px 12px",
    padding: "8px",
    float: "right",
    marginTop: theme.spacing(3),
    marginBottom: "10%",
  },

  [`& .${classes.buttonProgress}`]: {
    marginRight: "4px",
  },
}));

const BulkUpload = (props) => {
  const SUPPORTED_FORMATS = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-excel",
    "text/csv",
  ];

  const [jsonData, setJsonData] = useState([]);
  const keys = ["holiday_name", "holiday_date", "description"];
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && isValidExcelFile(file)) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = readExcelData(data);

        if (workbook) {
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];

          const convertedData = sheetToJson(sheet);
          setJsonData(convertedData);
        }
      };

      reader?.readAsBinaryString(file);
    } else {
      alert("Please select a valid Excel file.");
    }
  };

  const isValidExcelFile = (file) => {
    return SUPPORTED_FORMATS.includes(file.type);
  };

  const readExcelData = (data) => {
    // Manually parse the Excel data
    // You would need to implement your own parsing logic here
    // This example assumes the Excel data is in CSV format
    const lines = data.split("\n");
    const workbook = { SheetNames: [], Sheets: {} };
    const sheetName = "Sheet1";
    const sheet = {};
    const title = lines[0].split(",");
    for (let i = 1; i < lines.length - 1; i++) {
      const cells = lines[i].split(",");
      const row = {};
      for (let j = 0; j < title.length; j++) {
        row[title[j]] = cells[j];
      }
      sheet[i.toString()] = row;
    }

    workbook.SheetNames.push(sheetName);
    workbook.Sheets[sheetName] = sheet;

    return workbook;
  };

  const sheetToJson = (sheet) => {
    // Manually convert sheet data to JSON
    // You would need to implement your own conversion logic here
    const convertedData = [];

    for (const key in sheet) {
      if (sheet.hasOwnProperty(key)) {
        convertedData.push(sheet[key]);
      }
    }

    return convertedData;
  };

  function hasRequiredKeys(arrayOfObjects, requiredKeys) {
    const missingKeys = [];

    arrayOfObjects.forEach((obj) => {
      requiredKeys.forEach((key) => {
        if (
          !Object.prototype.hasOwnProperty.call(obj, key) &&
          !missingKeys.includes(key)
        ) {
          missingKeys.push(key);
        }
      });
    });

    return missingKeys;
  }
  const requiredKeys = ["holiday_date", "holiday_name", "description"];

  const keysPresent = hasRequiredKeys(jsonData, requiredKeys);

  const extractedData = jsonData.map((item) => {
    return {
      id: item.id,
      holiday_date: item.holiday_date,
      holiday_name: item.holiday_name,
      description: item.description,
    };
  });

  return (
    <Root>
      <Formik
        initialValues={{ file: "" }}
        validationSchema={Yup.object().shape({
          file: Yup.mixed()
            .test(
              "fileFormat",
              "Unsupported Format",
              (value) => value && SUPPORTED_FORMATS.includes(value.type)
            )
            .test(
              "fileSize",
              "Maximum file size is 10MB",
              (value) => value && value.size < 10242880
            ),
        })}
        onSubmit={(values) => {
          props.formSubmit(extractedData);
        }}
      >
        {({
          errors,
          handleSubmit,
          touched,
          values,

          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Container className={classes.container}>
              <Typography className={classes.label}>Excel file*</Typography>
              <OutlinedInput
                accept="text/csv"
                // className={classes.input}
                id="icon-button-file"
                name="file"
                fullWidth={true}
                error={touched.file && Boolean(errors.file)}
                // helperText={touched.file ? errors.file : ""}
                onChange={(e) => {
                  setFieldValue("file", e.currentTarget.files[0]);
                  handleFileChange(e);
                }}
                type="file"
              />

              <FormHelperText style={{ color: "#F44336", paddingLeft: 16 }}>
                {touched.file ? errors.file : ""}
              </FormHelperText>
              {keysPresent.length > 0 && (
                <FormHelperText
                  style={{ color: "#F44336", paddingLeft: 16, display: "flex" }}
                >
                  {keysPresent.map((item, index) => {
                    return (
                      <p key={index}>
                        {item}
                        {index === keysPresent.length - 1 ? "" : ","}
                      </p>
                    );
                  })}
                  <span style={{ marginLeft: "4px" }}>keys are missing</span>
                </FormHelperText>
              )}
              {jsonData.length > 0 && keysPresent.length === 0 && (
                <Accordion style={{ marginTop: "1rem" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      Holiday Preview
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {keys.map((key) => (
                            <TableCell
                              key={key}
                              sx={{ textTransform: "capitalize" }}
                            >
                              {key.replaceAll("_", " ")}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jsonData.map((item, index) => (
                          <TableRow key={index}>
                            {keys.map((key) => (
                              <TableCell key={key}>{item[key]}</TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </AccordionDetails>
                </Accordion>
              )}
              <Button
                color="primary"
                disabled={props.loading || keysPresent.length > 0}
                className={classes.popDownBtn}
                size="medium"
                type="submit"
                variant="contained"
              >
                {props.loading === false ? (
                  "Upload"
                ) : (
                  <>
                    <img
                      src={loadingSpinner}
                      alt="Logo"
                      className={classes.buttonProgress}
                    />{" "}
                    {"Uploading"}{" "}
                  </>
                )}
              </Button>
            </Container>
          </form>
        )}
      </Formik>
    </Root>
  );
};

export default BulkUpload;
